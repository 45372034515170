<template>
  <div class="row">
    <div class="col-md-12 container">
      <dashboard-box :title="$t('waybill.waybill_detail')" class="p-5">
        <template v-slot:preview>
          <div class="row" v-if="waybill != null" id="pdf-holder">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-4 row">
              <div class="col-2">
                <q-r-code
                    class="mr-2"
                    :font-classes="'mr-2 text-sm'"
                    :value="waybill.code_link"
                    size="75" level="H"
                    :code="waybill.code"
                ></q-r-code>
              </div>
              <div class="col-8 d-flex justify-content-center">
                <h2 class=" d-inline-block">
                  <span class="button-border">{{ $t('waybill.waybill_product_information')}} </span>
                </h2>
              </div>
              <div class="col-2 ">
                <img class="w-75px rounded-lg mx-2 float-right" :src="waybill.waybill_file" alt="">
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 row">
              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.action_date')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.created_at | momentDateFormat }}</p>
              </div>
              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.waybill_type')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.waybill_type.name }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.waybill_kind')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.waybill_kind.id }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.supplier_company')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.company_id }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.product_name')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.order_product_id }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.order_amount')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ parseInt(waybill.order.amount) }} {{ waybill.unit_id }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.tare_weight')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ parseInt(waybill.tare_amount) }} {{ waybill.unit_id }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.gross_incoming_amount')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg"> {{ parseInt(waybill.gross_amount) }} {{ waybill.unit_id }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.net_incoming_amount')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg"> {{ parseInt(waybill.net_amount) }} {{ waybill.unit_id }}</p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 row">
              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.waybill_date')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.waybill_date | momentDateFormat }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.document_no')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.document_number }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.order_no')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.order.order_number }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.product_color')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">Pembe</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.remaining_amount')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">10 KG</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.number_of_roll')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ parseInt(waybill.fabric_roll) }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.part_number')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.part_number }}</p>
              </div>

              <div class="col-8">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.depo_type')}}</p>
              </div>
              <div class="col-4">
                <p class="font-size-lg">{{ waybill.store.id }}</p>
              </div>
            </div>
            <hr>
            <div class="col-12 my-7" style="border: 1px solid rgba(150, 150, 150, 0.2);"></div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import {GET_ITEM_DETAIL_BY_ID} from "@/core/services/store/REST.module";
import moment from "moment";
import {LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";
import Vue from "vue";
import VueHtml2Canvas from "vue-html2canvas";
import QRCode from "@/assets/components/widget/QRCode";
Vue.use(VueHtml2Canvas);

export default {
  name: "WaybillPDF",
  components: {
    QRCode,
    DashboardBox,
  },
  computed: {
    ...mapGetters({

    }),
  },
  data() {
    return {
      id: this.$route.params.id,
      waybill: null,
    };
  },
  methods: {
    ...mapMutations({

    }),
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('waybill.waybill'), route: "waybill.index" },
      { title: this.$t('waybill.waybill_pdf') }
    ]);
    this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
      url: 'api/waybills/' + this.id,
      id: this.id,
      acceptPromise: true
    }).then((result) => {
      if(result.status){
        self.waybill = result.data;
        self.waybill.waybill_date = moment(self.waybill.waybill_date, LARAVEL_DATE_FORMAT)
        self.waybill.created_at = moment(self.waybill.created_at, LARAVEL_DATE_TIME_FORMAT)

        setTimeout(function(){
          self.downloadPdf('pdf-holder', self.waybill.order.model)
        }, 1000);
      }
    })
  }
}
</script>

<style scoped>
.button-border{
  border-bottom: 1px solid rgba(150, 150, 150, 0.4);
}
</style>
